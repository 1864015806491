((h,o,t,j,a,r) => {

  let tanfHotjarId = 864760;
  let cogentHitjatId = 1633083;
  let hId;
  let currentLocation = window.location.href;
  let n = currentLocation.indexOf("cogent");

  if( n > -1 ){
      hId = cogentHitjatId;
  }else{
      hId = tanfHotjarId;
  }

  h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
  h._hjSettings={hjid:hId,hjsv:6};
  a=o.getElementsByTagName('head')[0];
  r=o.createElement('script');r.async=1;
  r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
  a.appendChild(r);
})(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
